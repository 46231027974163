import React from "react";
import { Menu, Layout } from "antd";
import { useHistory } from "react-router-dom";

const { Header } = Layout;

export default function HeaderLayout() {
  const history = useHistory();

  function handleLogoutButton() {
    localStorage.removeItem("token");
    history.push("/login");
  }

  return (
    <Header>
      <div className="logo" />
      <Menu
        theme="dark"
        style={{ float: "right" }}
        mode="horizontal"
        defaultSelectedKeys={["1"]}
      >
        <Menu.Item key="1">
          <a href="/stores">Stores</a>
        </Menu.Item>
        <Menu.Item key="2">Insights</Menu.Item>
        <Menu.Item key="3" onClick={handleLogoutButton}>
          Logout
        </Menu.Item>
      </Menu>
    </Header>
  );
}
