import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import App from "./App";
import Login from "./pages/Login";
import Stores from "./pages/Stores";
import StoreDetail from "./pages/StoreDetail";

export default function Routes(props) {
  return (
    <Router {...props}>
      <Switch>
        <PrivateRoute exact path="/">
          <App>
            <Stores />
          </App>
        </PrivateRoute>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute exact path="/stores">
          <App>
            <Stores />
          </App>
        </PrivateRoute>
        <PrivateRoute path="/stores/:id">
          <App>
            <StoreDetail />
          </App>
        </PrivateRoute>
        <Route path="*">
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() ? children : <Redirect to={{ pathname: "/login" }} />
      }
    />
  );
}

function isAuthenticated() {
  if (localStorage.getItem("token")) return true;
}
