import React from "react";
import { Form, Spin, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import "./style.css";

export default function Login() {
  const { post, loading } = useFetch();
  const history = useHistory();

  const onFinish = (values) => {
    post("oauth/token", {
      grant_type: "password",
      email: values.email,
      password: values.password,
    }).then((data) => {
      localStorage.setItem("token", data.access_token);
      history.push("/stores");
    });
  };

  return (
    <div className="login-page">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={loading}
          >
            {loading && <Spin />}
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
