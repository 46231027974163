import React, { useState, useEffect } from "react";
import { Table, Layout, Breadcrumb } from "antd";

import useFetch from "../../hooks/useFetch";
import columns from "./columns";
import Filter from "../../components/Filter";

const { Content } = Layout;

export default function Stores() {
  const initialFilterParams = {
    id_eq: null,
    url_cont: null,
    product_price_gteq: null,
    product_price_lteq: null,
  };

  const [stores, setStores] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalStoreNumber, setTotalStoreNumber] = useState(null);
  const [filterParams, setFilterParams] = useState(initialFilterParams);
  const { post, loading } = useFetch();

  useEffect(() => {
    const requestParams = {
      page: pageNumber,
      q: filterParams,
    };

    post("stores/search", requestParams).then((data) => {
      const computedStores = data.stores.map((store) => {
        store.key = store.id;
        return store;
      });

      setStores(computedStores);
      setTotalStoreNumber(data.meta.total_count);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterParams]);

  function handlePaginationButton(pageNumber) {
    setPageNumber(pageNumber);
  }

  function handleSearchButton(params) {
    setFilterParams(params);
  }

  return (
    <>
      <Content style={{ padding: "0 50px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/stores">Stores</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Filter
          filterParams={filterParams}
          handleSearchButton={handleSearchButton}
        />

        <Table
          columns={columns}
          dataSource={stores}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
            defaultCurrent: 1,
            onChange: handlePaginationButton,
            total: totalStoreNumber,
            showSizeChanger: false,
            showTotal: () => {
              return `Total ${totalStoreNumber} stores`;
            },
          }}
        />
      </Content>
    </>
  );
}
