import React, { useRef, useState } from "react";
import { Card, DatePicker, Button, Input } from "antd";
import "./style.css";

const { RangePicker } = DatePicker;

export default function Filter(props) {
  const idInputRef = useRef();
  const urlInputRef = useRef();
  const minPriceInputRef = useRef();
  const maxPriceInputRef = useRef();
  const [remoteCreatedAtRange, setRemoteCreatedAtRange] = useState([
    null,
    null,
  ]);

  const [remoteUpdatedAtRange, setRemoteUpdatedAtRange] = useState([
    null,
    null,
  ]);

  const [remotePublishedAtRange, setRemotePublishedAtRange] = useState([
    null,
    null,
  ]);

  function onChange(date, dateString, type) {
    if (type === "createdAt")
      setRemoteCreatedAtRange([dateString[0], dateString[1]]);
    else if (type === "updatedAt")
      setRemoteUpdatedAtRange([dateString[0], dateString[1]]);
    else if (type === "publishedAt")
      setRemotePublishedAtRange([dateString[0], dateString[1]]);
  }

  return (
    <Card className="filter-card">
      <div className="filters">
        <div className="input-filters">
          ID:
          <Input ref={idInputRef} />
          URL:
          <Input ref={urlInputRef} />
          <div className="price-filter">
            <span>
              Min Price:
              <Input ref={minPriceInputRef} />
            </span>
            <span>
              Max Price:
              <Input ref={maxPriceInputRef} />
            </span>
          </div>
        </div>
        <div className="date-pickers">
          Created:{" "}
          <RangePicker
            className="date-picker-input"
            name="deneme"
            onChange={(date, dateString) =>
              onChange(date, dateString, "createdAt")
            }
          />
          Updated:{" "}
          <RangePicker
            className="date-picker-input"
            onChange={(date, dateString) =>
              onChange(date, dateString, "updatedAt")
            }
          />
          Published:{" "}
          <RangePicker
            className="date-picker-input"
            onChange={(date, dateString) =>
              onChange(date, dateString, "publishedAt")
            }
          />
        </div>
      </div>
      <div className="filter-button">
        <Button
          type="primary"
          onClick={() =>
            props.handleSearchButton({
              id_eq: idInputRef.current.state.value,
              url_cont: urlInputRef.current.state.value,
              product_price_gteq: minPriceInputRef.current.state.value,
              product_price_lteq: maxPriceInputRef.current.state.value,
              product_remote_created_at_gteq: remoteCreatedAtRange[0],
              product_remote_created_at_lteq: remoteCreatedAtRange[1],
              product_remote_updated_at_gteq: remoteUpdatedAtRange[0],
              product_remote_updated_at_lteq: remoteUpdatedAtRange[1],
              product_remote_published_at_gteq: remotePublishedAtRange[0],
              product_remote_published_at_lteq: remotePublishedAtRange[1],
            })
          }
        >
          Search
        </Button>
      </div>
    </Card>
  );
}
