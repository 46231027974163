import React from "react";
import { Tag, Button, Space } from "antd";
import formatDate from "../../utils/formatDate";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (text, record) => (
      <Link to={`stores/${record.id}`}>{record.id}</Link>
    ),
  },
  {
    title: "Url",
    dataIndex: "url",
    key: "url",
    render: (text) => (
      <a href={`https://${text}`} target="_blank">
        {text}
      </a>
    ),
  },
  {
    title: "Price",
    dataIndex: "product",
    key: "price",
    render: (product) => {
      return <Tag color="volcano">${Number(product.price).toFixed(2)}</Tag>;
    },
  },
  {
    title: "Created",
    dataIndex: "product",
    key: "createdAt",
    render: (product) => formatDate(product.remote_created_at),
  },
  {
    title: "Updated",
    dataIndex: "product",
    key: "updatedAt",
    render: (product) => formatDate(product.remote_updated_at),
  },
  {
    title: "Product Name",
    dataIndex: "product",
    key: "productName",
    render: (product) => {
      if (product.name.length > 40) {
        return product.name.substring(0, 40) + "...";
      } else {
        return product.name;
      }
    },
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Button type="normal" shape="round">
        <Link to={`stores/${record.id}`}>Show</Link>
      </Button>
    ),
  },
];

export default columns;
