import React from "react";
import HeaderLayout from "./layouts/HeaderLayout";

import "./App.css";

function App(props) {
  return (
    <>
      <HeaderLayout />
      {props.children}
    </>
  );
}

export default App;
