import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Layout, Breadcrumb } from "antd";
import formatDate from "../../utils/formatDate";
import { Card } from "antd";

const { Content } = Layout;

export default function StoreDetail() {
  const { id } = useParams();
  const [store, setStore] = useState(null);
  const { get, loading } = useFetch();

  useEffect(() => {
    get(`stores/${id}`).then((data) => {
      setStore(data.store);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content style={{ padding: "0 50px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/stores">Stores</a>
        </Breadcrumb.Item>
        {store && (
          <Breadcrumb.Item>
            <a href={`/stores/${store.id}`}>Detail</a>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      {store && (
        <Card
          title={
            <a href={"https://" + store.url} target="_blank">
              {store.url}
            </a>
          }
          extra={<a href="#">#{store.id}</a>}
          style={{ width: 350 }}
        >
          <p>
            Price: <strong>${store.product.price}</strong>
          </p>
          <p>
            Product: <strong>{store.product.name}</strong>
          </p>
          <p>
            CreatedAt:{" "}
            <strong>{formatDate(store.product.remote_created_at)}</strong>
          </p>
          <p>
            PublishedAt:{" "}
            <strong>{formatDate(store.product.remote_published_at)}</strong>
          </p>
          <p>
            UpdatedAt:{" "}
            <strong>{formatDate(store.product.remote_updated_at)}</strong>
          </p>
        </Card>
      )}
    </Content>
  );
}
